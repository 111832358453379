import { Badge, Card, Col, Container, Form, InputGroup, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';
import styles from './TrailerLocationScreen.module.scss';
import { MapContainer, Popup, TileLayer, CircleMarker, Polyline } from 'react-leaflet';
import MarkerImage from 'assets/img/marker-icon-2x.png';
import RedMarkerImage from 'assets/img/red_marker-icon-2x.png';
import GreenMarkerImage from 'assets/img/green_marker-icon-2x.png';
import L from 'leaflet';
import { useTranslation } from 'react-i18next';
import Loading from 'common/services/Loading';
import { useEffect, useState } from 'react';
import Logger from 'common/services/Logger';
import { DATE_TIME_FORMAT_DEFAULT, LOGGER_LOG_TYPE } from 'Config';
import toast from 'react-hot-toast';
import { TrackingSearchCriteria } from 'api/trackings/models/TrackingSearchCriteria';
import { TrackingDto } from 'api/trackings/models/TrackingDto';
import TrackingsService from 'api/trackings/TrackingsService';
import RoutingMachine from 'common/components/maps/RoutineMachine';
import { TrackingRouteDto } from 'api/trackings/models/TrackingRouteDto';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { useGeoLocator, addressFormat } from 'common/services/GeoLocator';
import { faLocationDot, faCalendarDays, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';

type Props = {
    startDate: Date | null,
    trailerId: string;
};

const TrailerLocationScreen: React.FC<Props> = ({ trailerId, startDate }: Props) => {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState<number>(1);
    const [position, setPosition] = useState<TrackingDto>();
    const [trackingRoutes, setTrackingRoutes] = useState<TrackingRouteDto[]>([]);
    const [waypoints, setWaypoints] = useState<L.LatLng[]>([]);
    const [selectedRouteKey, setSelectedRouteKey] = useState<string>('');
    const [waypointsInfo, setWaypointsInfo] = useState<TrackingDto[]>([]);
    const redOptions = { color: 'red' }
    const [hidePoints, setHidePoints] = useState<boolean>(false);
    const [criteria, setCriteria] = useState<TrackingSearchCriteria>({
        itemsPerPage: 500,
        page: 1,
        orderBy: 'desc',
        orderColumn: 'start_date',
        date: startDate,
        trailerId: trailerId
    });

    const search = async () => {
        try {
            Loading.show();
            const position = await TrackingsService.getPosition(criteria);
            setPosition(position);
            const routes = await TrackingsService.getRoutes(criteria);
            setTrackingRoutes(routes);
            setWaypoints([]);
            setWaypointsInfo([]);
            setSelectedRouteKey('');
            console.log('search');
            setRefresh(refresh + 1);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get device model list', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const loadWaypoints = async (start: number, stop: number) => {
        const _criteria = { ...criteria, start, stop };
        const _waypoints = await TrackingsService.getWaypoints(_criteria);
        const waypoints: L.LatLng[] = [];
        if (_waypoints) {
            _waypoints.forEach(i => {
                waypoints.push(L.latLng(i.latitude ?? 0, i.longitude ?? 0));
            });
        }
        setSelectedRouteKey(`georoute_${start}_${stop}`)
        setWaypointsInfo(_waypoints);
        setWaypoints(waypoints);
        setRefresh(refresh + 1);
    }

    const renderTooltip = (props: any) => (
        <Tooltip {...props}>
            {t('location.current')}
        </Tooltip>
    );

    const moveToCurrent = () => {
        setSelectedRouteKey('');
        setWaypointsInfo([]);
        setWaypoints([]);
        setRefresh(refresh + 1);
    }

    useEffect(() => {
        search().catch(console.error);
    }, [criteria]);

    const renderInfo = (i: number) => {
        if (waypointsInfo[i]) {
            return <>
                <h6>{t('location.date')}: <Badge bg="secondary"><DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={waypointsInfo[i].date} /></Badge></h6>
                <h6>{t('location.latitude')}: <Badge bg="secondary">{waypointsInfo[i].latitude ?? 0}</Badge></h6>
                <h6>{t('location.longitude')}: <Badge bg="secondary">{waypointsInfo[i].longitude ?? 0}</Badge></h6>
                <h6>{t('location.vehicle')}: <Badge bg="secondary">{waypointsInfo[i].licensePlate ?? ''}</Badge></h6>
            </>
        }
        return <></>
    }

    const loadIcon = (i: number) => {
        if (waypointsInfo) {
            if (i < 1) {
                return RedMarkerImage;
            } else if (i > 0 && i < waypointsInfo.length - 1) {
                return MarkerImage;
            } else {
                return GreenMarkerImage;
            }
        }
        return MarkerImage;
    }

    const GeoPosition = ({ latitude, longitude, icon }: { latitude: number, longitude: number, icon: string }) => {
        const { isLoading, error, data } = useGeoLocator(latitude, longitude);
        if (isLoading) return <LoadingSpinner />;
        if (error) console.log('An error occurred while fetching the user data ', error);
        return (<InputGroup className="mb-3">
            <div className={icon}>
                <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <Form.Text>{addressFormat(data)}</Form.Text>
        </InputGroup>);
    };

    const GeoLocatorItem = ({ id, latitude, longitude, icon, address }: { id: number, latitude: number, longitude: number, icon: string, address: string }) => {
        let formatedAddress = '';
        if (address) {
            formatedAddress = address;
        } else {
            const { isLoading, error, data } = useGeoLocator(latitude, longitude);
            if (isLoading) return <LoadingSpinner />;
            if (error) console.log('An error occurred while fetching the user data ', error);
            formatedAddress = addressFormat(data);
            try {
                TrackingsService.updateAddress(id, formatedAddress).catch(console.error);
            } catch (error) {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t update route address', error);
            }
        }
        return (<InputGroup className="mb-3">
            <div className={icon}>
                <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <Form.Control size="sm" type='text' value={formatedAddress} onChange={() => { }} />
        </InputGroup>);
    };

    const GeoRoutes = ({ start, stop, startLatitude, startLongitude, stopLatitude, stopLongitude, startDate, stopDate, startAddress, stopAddress }:
        { start: number, stop: number, startLatitude: number, startLongitude: number, stopLatitude: number, stopLongitude: number,
          startDate: Date, stopDate: Date, startAddress: string, stopAddress: string }) => {
        const diffInTime = new Date(stopDate).getTime() - new Date(startDate).getTime();
        const hours = Math.floor(diffInTime / (1000 * 60 * 60))
        const minutes = Math.round((diffInTime - (hours * 1000 * 60 * 60)) / (1000 * 60));
        return (
            <Card border={selectedRouteKey === `georoute_${start}_${stop}` ? 'info' : 'secondary'} className={styles.routeItem} onClick={() => loadWaypoints(start, stop)} key={`georoute_${start}_${stop}`}>
                <Card.Header bsPrefix={selectedRouteKey === `georoute_${start}_${stop}` ? styles.cardHeaderSelected : styles.cardHeader}>
                    <div className={styles.geoLocationHeaderFooter}>
                        <Form.Text muted><FontAwesomeIcon icon={faCalendarDays} className='mx-1' /><strong>{t('location.start')}</strong>&nbsp;<DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={startDate} /></Form.Text>
                        <Form.Text muted><FontAwesomeIcon icon={faCalendarDays} className='mx-1' /><strong>{t('location.end')}</strong>&nbsp;<DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={stopDate} /></Form.Text>
                    </div>
                </Card.Header>
                <Card.Body>
                    <GeoLocatorItem id={start} latitude={startLatitude} longitude={startLongitude} icon={styles.sourcePin} address={startAddress}/>
                    <GeoLocatorItem id={stop} latitude={stopLatitude} longitude={stopLongitude} icon={styles.destinationPin} address={stopAddress}/>
                </Card.Body>
                <Card.Footer bsPrefix={selectedRouteKey === `georoute_${start}_${stop}` ? styles.cardFooterSelected : styles.cardFooter}>
                    <div className={styles.geoLocationHeaderFooter}>
                        <Form.Text muted></Form.Text>
                        <Form.Text muted><FontAwesomeIcon icon={faClock} className='mx-1' /><strong>{hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}h</strong></Form.Text>
                    </div>
                </Card.Footer>
            </Card>
        );
    };

    const renderMap = () => {
        const zoomPosition = waypointsInfo && waypointsInfo.length > 0 ? waypointsInfo[waypointsInfo.length - 1] : position;
        if (position && zoomPosition) {
            return <>
                <MapContainer center={[zoomPosition.latitude ?? 0, zoomPosition.longitude ?? 0]} maxZoom={18} minZoom={6} zoom={12} scrollWheelZoom={true} className={styles.map}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {!(waypointsInfo && waypointsInfo.length > 0) && <CircleMarker center={[position.latitude ?? 0, position.longitude ?? 0]} pathOptions={{ fillColor: 'blue' }} radius={25}>
                        <Popup>
                            <h6>{t('location.date')}: <Badge bg="secondary"><DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={position.date} /></Badge></h6>
                            <h6>{t('location.latitude')}: <Badge bg="secondary">{position.latitude ?? 0}</Badge></h6>
                            <h6>{t('location.longitude')}: <Badge bg="secondary">{position.longitude ?? 0}</Badge></h6>
                        </Popup>
                    </CircleMarker>}
                    <RoutingMachine waypoints={waypoints} renderInfo={renderInfo} loadIcon={loadIcon} hide={hidePoints} />
                    <Polyline pathOptions={redOptions} positions={waypoints} />
                </MapContainer></>
        } else {
            return <></>
        }
    }

    return (<>
        <Card className={styles.currentPositionCard}>
            <Card.Body>
                <Stack direction="horizontal" gap={3}>
                    <div className="p-2" onClick={() => moveToCurrent()}>
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                            <Row>
                                <Col xs={12}>
                                    {position && <GeoPosition latitude={position.latitude ?? 0} longitude={position.longitude ?? 0} icon={styles.currentPin} />}
                                </Col>
                            </Row>
                        </OverlayTrigger>
                    </div>
                    <div className="ms-auto" onClick={() => moveToCurrent()}>
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                            <Row>
                                <Col xs={12}></Col>
                            </Row>
                        </OverlayTrigger>
                    </div>
                    <div className="vr" />
                    <Form.Check type="switch" label={t('location.hide_points')} onChange={(e) => { setHidePoints(e.target.checked); setRefresh(refresh + 1); }} />
                </Stack>
            </Card.Body>
        </Card>
        <Row>
            {trackingRoutes && trackingRoutes.length > 0 && <Col xs={12} md={4}>
                <Container fluid className={styles.geoLocation}>
                    {trackingRoutes.map((route, i) => <GeoRoutes key={i} start={route.start} stop={route.stop} startLatitude={route.startLatitude} startLongitude={route.startLongitude} stopLatitude={route.stopLatitude} stopLongitude={route.stopLongitude} startDate={route.startDate} stopDate={route.stopDate} startAddress={route.startAddress} stopAddress={route.stopAddress} />)}
                </Container>
            </Col>}
            <Col xs={12} md={trackingRoutes && trackingRoutes.length > 0 ? 8 : 12}>
                <Container fluid className={styles.map} key={`map_${refresh}`}>
                    {renderMap()}
                </Container>
            </Col>
        </Row>
    </>);
}

export default TrailerLocationScreen;
