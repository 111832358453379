import { Badge, Button, Card, Col, Container, Form, InputGroup, Modal, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';
import styles from './AggregateLocationScreen.module.scss';
import { MapContainer, TileLayer, Circle } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import Loading from 'common/services/Loading';
import { useEffect, useState } from 'react';
import Logger from 'common/services/Logger';
import { DATE_TIME_FORMAT_DEFAULT, LOGGER_LOG_TYPE } from 'Config';
import toast from 'react-hot-toast';
import { TrackingDto } from 'api/trackings/models/TrackingDto';
import TrackingsService from 'api/trackings/TrackingsService';
import DateFormat from 'common/components/dateFormat/dateFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';
import DrawTools, { LocationArea } from 'common/components/maps/DrawTools';
import drawLocales, { Language } from 'leaflet-draw-locales'
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import RoutingMachine from 'common/components/maps/RoutineMachine';
import CarImage from 'assets/img/car.png';
import TrailerImage from 'assets/img/trailer.png';
import L from 'leaflet';
import { HotSpotDto } from 'api/hotSpots/models/HotSpotDto';
import HotSpotsService from 'api/hotSpots/HotSpotsService';
import { useGeoLocator, addressFormat } from 'common/services/GeoLocator';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { AggregateTrackingSearchCriteria } from 'api/trackings/models/AggregateTrackingSearchCriteria';
import ZoomLevelControl from 'common/components/maps/ZoomLevelControl';

type Props = {
    forceRefresh: number
    hotSpotId: string;
    zoomLevel: number;
    onZoomChange: (level: number) => void;
    refreshHotSpots?: (hotSpotId: string) => void;
    onRemove?: boolean;
    cleanSelected: () => void;
    hideModal: () => void;
};

const AggregateLocationScreen: React.FC<Props> = ({ forceRefresh, refreshHotSpots, hotSpotId, onRemove, cleanSelected, hideModal, zoomLevel, onZoomChange }: Props) => {
    const { t } = useTranslation();
    const [refresh, setRefresh] = useState<number>(1);
    const [position, setPosition] = useState<GeolocationPosition>();
    const [area, setArea] = useState<LocationArea>();
    const [points, setPoints] = useState<L.LatLng[]>([]);
    const [pointsInfo, setPointsInfo] = useState<TrackingDto[]>([]);
    const [showSaveAreaModal, setShowSaveAreaModal] = useState(false);
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [validated, setValidated] = useState(false);
    const [hotSpot, setHotSpot] = useState<HotSpotDto | null>(null);
    const [itemToRemove, setItemToRemove] = useState<HotSpotDto | null>(null);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [formHotSpot, setFormHotSpot] = useState({
        id: '',
        name: '',
        latitude: 0,
        longitude: 0,
        radius: 0,
        rowVersion: '',
    });
    const [criteria, setCriteria] = useState<AggregateTrackingSearchCriteria>({
        itemsPerPage: 500,
        page: 1,
        latitude: 0,
        longitude: 0,
        radius: 0
    });

    function handleChange(e: any) {
        const key = e.target.name;
        const value = e.target.value;
        setFormHotSpot({ ...formHotSpot, [key]: value });
    }

    const loadHotSpot = async () => {
        try {
            Loading.show();
            const hotSpot = await HotSpotsService.getById(hotSpotId);
            if (hotSpot) {
                await HotSpotsService.select(hotSpot);
                const area: LocationArea = { latitude: hotSpot.latitude, longitude: hotSpot.longitude, mRadius: hotSpot.radius, radius: hotSpot.radius };
                setArea(area);
                setHotSpot(hotSpot);
            } else {
                setArea(undefined);
                setHotSpot(null);
            }
            setRefresh(refresh + 1);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get device model list', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const search = async () => {
        try {
            Loading.show();
            const _pointsInfo: TrackingDto[] = [];
            const _points: L.LatLng[] = [];
            if (area) {
                const _criteria = { ...criteria, latitude: area.latitude, longitude: area.longitude, radius: area.mRadius };
                setCriteria(_criteria);
                const page = await TrackingsService.getAggregatePosition(_criteria);
                const pointsInfo = page.items;
                if (pointsInfo) {
                    pointsInfo.forEach(i => {
                        _points.push(L.latLng(i.latitude ?? 0, i.longitude ?? 0));
                        _pointsInfo.push(i);
                    });
                }
            }
            setPointsInfo(_pointsInfo);
            setPoints(_points);
            setRefresh(refresh + 1);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get device model list', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const setCurrentPosition = (position: GeolocationPosition) => {
        try {
            setPosition(position);
            setRefresh(refresh + 1);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get device model list', error);
            toast.error(t('messages.error_load_info'));
        } finally {
            Loading.hide();
        }
    }

    const onCreateArea = (area: LocationArea) => {
        setArea(area);
        setShowSaveAreaModal(true);
        setFormHotSpot({ ...formHotSpot, name: '', latitude: area.latitude, longitude: area.longitude, radius: area.mRadius });
    }

    const onSubmitHotSpot = async (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        if (form.checkValidity() === false) {
            return;
        }
        try {
            Loading.show();
            const model: HotSpotDto = {
                name: formHotSpot.name,
                latitude: formHotSpot.latitude,
                longitude: formHotSpot.longitude,
                radius: formHotSpot.radius
            };
            if (model) {
                const id = await HotSpotsService.create(model);
                if (refreshHotSpots) {
                    refreshHotSpots(id);
                }
            }
            Loading.hide();
            setShowSaveAreaModal(false);
            toast.success(t('messages.record_save_success'));
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t create hotspot', error);
            toast.error(t('messages.record_save_error'));
            Loading.hide();
        }
    };

    const renderHotSpotModal = () => {
        return <Modal show={showSaveAreaModal} onHide={() => setShowSaveAreaModal(false)}>
            <Form onSubmit={onSubmitHotSpot} noValidate validated={validated}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('messages.save_hot_spot')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="name">
                        <Form.Label>{t('location.hot_spot_name')}</Form.Label>
                        <Form.Control
                            type="text"
                            required
                            autoComplete='false'
                            name="name"
                            placeholder={t('location.hot_spot_name')}
                            onChange={handleChange}
                            autoFocus
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" className={styles.button}>
                        {t('common.save')}
                    </Button>
                    <Button variant="secondary" className={styles.button} onClick={() => setShowSaveAreaModal(false)}>
                        {t('common.cancel')}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>;
    }

    const GeoLocatorItem = ({ latitude, longitude, icon }: { latitude: number, longitude: number, icon: string }) => {
        const { isLoading, error, data } = useGeoLocator(latitude, longitude);
        if (isLoading) return <LoadingSpinner />;
        if (error) console.log('An error occurred while fetching the user data ', error);
        return (<InputGroup className="mb-3">
            <div className={icon}>
                <FontAwesomeIcon icon={faLocationDot} />
            </div>
            <Form.Control size="sm" type='text' value={addressFormat(data)} onChange={() => { }} />
        </InputGroup>);
    };

    const GeoPositions = ({ date, latitude, longitude, licensePlate, trailerLicensePlate, designation, driverName }:
        { date: Date, latitude: number, longitude: number, licensePlate: string, trailerLicensePlate?: string, designation?: string, driverName?: string }) => {
        return (
            <Card border={'secondary'} className={styles.routeItem}>
                <Card.Header bsPrefix={styles.cardHeader}>
                    <div className={styles.geoLocationHeaderFooter}>
                        <DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={date} />
                    </div>
                </Card.Header>
                <Card.Body>
                    <GeoLocatorItem latitude={latitude} longitude={longitude} icon={styles.currentPin} />
                </Card.Body>
                <Card.Footer bsPrefix={styles.cardFooter}>
                    <div className={styles.geoLocationHeaderFooter}>
                        <div>
                            {trailerLicensePlate ? <img src={TrailerImage} className={styles.logo} /> : <img src={CarImage} className={styles.logo} />}
                            {trailerLicensePlate ?? licensePlate}
                            {designation ? ` | ${designation}` : ''}
                        </div>
                        <div>
                            {driverName}
                        </div>
                    </div>
                </Card.Footer>
            </Card>
        );
    };

    const renderInfo = (i: number) => {
        if (pointsInfo[i]) {
            return <>
                <h6>{t('location.date')}: <Badge bg="secondary"><DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={pointsInfo[i].date} /></Badge></h6>
                <h6>{t('location.latitude')}: <Badge bg="secondary">{pointsInfo[i].latitude ?? 0}</Badge></h6>
                <h6>{t('location.longitude')}: <Badge bg="secondary">{pointsInfo[i].longitude ?? 0}</Badge></h6>
                <h6>{t('location.vehicle')}: <Badge bg="secondary">{pointsInfo[i].licensePlate ?? ''}</Badge></h6>
                {pointsInfo[i].trailerLicensePlate && <h6>{t('location.trailer')}: <Badge bg="secondary">{pointsInfo[i].trailerLicensePlate ?? ''}</Badge></h6>}
            </>
        }
        return <></>
    }

    const loadIcon = (i: number) => {
        const pi = pointsInfo[i];
        if (pi.isTag) {
            return TrailerImage;
        } else {
            return CarImage;
        }
    }

    const showRemoveItemDialog = async (item: HotSpotDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const remove = async () => {
        if (itemToRemove === null) {
            toast.error(t('messages.record_delete_error'));
            return;
        }
        try {
            await HotSpotsService.remove(itemToRemove);
            toast.success(t('messages.record_delete_success'));
            cleanSelected();
            hideModal();
            if (refreshHotSpots) {
                refreshHotSpots('');
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t delete simCards', error);
            toast.error(t('messages.record_delete_error'));
        }
    };

    useEffect(() => {
        if (forceRefresh > 1) {
            setArea(undefined);
            setHotSpot(null);
            setPoints([]);
            setRefresh(refresh + 1);
        }
    }, [forceRefresh]);

    useEffect(() => {
        if (hotSpotId) {
            loadHotSpot().catch(console.error);
        } else {
            Loading.show();
            navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => setCurrentPosition(position), () => Loading.hide());
        }
    }, [hotSpotId]);

    useEffect(() => {
        if (onRemove && hotSpot) {
            showRemoveItemDialog(hotSpot);
        } else {
            onCancelRemove();
        }
    }, [onRemove]);

    useEffect(() => {
        if (area) {
            search();
        }
    }, [area]);

    useEffect(() => {
        if (userProfile) {
            drawLocales(userProfile?.languageCode as Language);
        }
    }, [userProfile]);

    const renderMap = () => {
        return <><p>{zoomLevel}</p>
            <MapContainer center={[area ? area.latitude : position?.coords?.latitude ?? 52.516316, area ? area.longitude : position?.coords?.longitude ?? 13.377825]} maxZoom={18} minZoom={6} zoom={zoomLevel} scrollWheelZoom={true} className={styles.map}>
                {zoomLevel && <ZoomLevelControl onZoomChange={onZoomChange} level={zoomLevel} />}
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {area ?
                    <Circle center={[area.latitude, area.longitude]} radius={area.mRadius}></Circle> :
                    <DrawTools onCreated={onCreateArea} />
                }
                {
                    <RoutingMachine waypoints={points} renderInfo={renderInfo} loadIcon={loadIcon} hide={false} />
                }
            </MapContainer></>
    }

    return (<Row>
        {pointsInfo && pointsInfo.length > 0 && <Col xs={12} md={4}>
            <Container fluid className={styles.geoLocation}>
                {pointsInfo.map((info, i) => <GeoPositions key={i} date={info.date} latitude={info.latitude ?? 0} longitude={info.longitude ?? 0} licensePlate={info.licensePlate ?? ''} trailerLicensePlate={info.trailerLicensePlate} driverName={info.driverName} designation={info.designation} />)}
            </Container>
        </Col>}
        <Col xs={12} md={pointsInfo && pointsInfo.length > 0 ? 8 : 12}>
            <Container fluid className={styles.map} key={`map_${refresh}`}>
                {renderMap()}
            </Container>
            {renderHotSpotModal()}
            <QuestionYesNo onNo={hideModal} onYes={remove} isVisible={showRemoveModal} message={t('messages.remove_record_with_ident', { name: itemToRemove?.name ?? '' })} />
        </Col>
    </Row>);
}

export default AggregateLocationScreen;
