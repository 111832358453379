import { DATE_TIME_FORMAT_DEFAULT } from 'Config';
import DateFormat from 'common/components/dateFormat/dateFormat';
import LoadingSpinner from 'common/components/loading/LoadingSpinner';
import { addressFormat, useGeoLocator } from 'common/services/GeoLocator';
import styles from './AlarmSettingScreen.module.scss';

interface Props {
    latitude: number;
    longitude: number;
    triggerDate: Date
}

const TriggerAddress = ({ latitude, longitude, triggerDate }: Props) => {
    const { isLoading, error, data } = useGeoLocator(latitude, longitude);
    if (isLoading) return <LoadingSpinner />;
    if (error) console.log('An error occurred while fetching the user data ', error);

    return (
        <div>
            <span className={styles.labelAddress}>{addressFormat(data)}</span>
            <div>
                <div className={styles.labelDate}><DateFormat format={DATE_TIME_FORMAT_DEFAULT} value={triggerDate} /></div>
            </div>
        </div>
    )
}

export default TriggerAddress;
