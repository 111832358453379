import pt from 'assets/translations/pt.json';
import en from 'assets/translations/en.json';

/**
 * Languages
 */
export const DEFAULT_LANGUAGE = 'pt';
export const DEFAULTNS = 'translations';
export const LANGUAGES_RESOURCES = {
    pt: {
        [DEFAULTNS]: pt,
    },
    en: {
        [DEFAULTNS]: en,
    },
};
export const USE_LOCAL_TRANSLATIONS = process.env.REACT_APP_USE_LOCAL_TRANSLATIONS?.trim() === 'true';

/**
* API
*/
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_URL;

/**
 * Pagination
 */
export const DEFAULT_PAGINATION_ITEMS_PER_PAGE = 16;
export const MAX_PAGINATION_ITEMS_PER_PAGE = 999999;

/**
 * Storage
 */
export const STORAGE_KEY = 'cQfTjWnZr4u7x!A%D*G-KaNdRgUkXp2s';
export const STORAGE = {
    CURRENT_LOCALE: 'CURRENT_LOCALE',
    AUTH_USER_TOKEN: 'AUTH_USER_TOKEN',
    AUTH_USER_PROFILE: 'AUTH_USER_PROFILE',
    LAST_SEEN_GROUPS: 'LAST_SEEN_GROUPS',
};

/**
 * Http errors
 */
export const HTTP_ERRORS = {
    UNAUTHORIZED: 401,
    CONFLICT: 409
};

/**
 * Logger
 */
export enum LOGGER_MESSAGE_TYPE {
    INFO = 1,
    ERROR,
}
export enum LOGGER_LOG_TYPE {
    REQUEST
}
export const LOGGER_PRINT_LOGS = Boolean(process.env.REACT_APP_LOGGER_PRINT_LOGS);
export const LOGGER_PRINT_LOGS_LEVEL = LOGGER_MESSAGE_TYPE.INFO;

/**
 * MIMETYPES
 */
export const MIMETYPES_IMAGE = 'image/png, image/gif, image/jpeg';
export const MIMETYPES_ALL_IMAGE = 'image/*';

export const isTablet = (width: number) => {
    return (width <= 1200);
};
/**
 * DATE FORMAT
 */
export const DATE_FORMAT_DEFAUT = 'DD/MM/YYYY';
export const DATEPICKER_FORMAT_DEFAULT = 'dd/MM/yyyy';
export const DATE_TIME_FORMAT_DEFAUT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_MONTH_YEAR = 'MM/YYYY';
export const DATE_TIME_FORMAT_DEFAULT = 'DD/MM/YYYY | HH:mm';
export const DATE_FORMAT_MONTH_TEXT_YEAR = 'MMM/YYYY';
export const TIME_FORMAT_DEFAULT = 'HH:mm';

/**
 * React Form
 */

/**
 * POLICIES
 */
export const POLICIES = {
    COMPANIES_READ: 'COMPANIES_READ',
    COMPANIES_WRITE: 'COMPANIES_WRITE',
    CONFIG_DEVICE_BRAND_READ: 'CONFIG_DEVICE_BRAND_READ',
    CONFIG_DEVICE_BRAND_WRITE: 'CONFIG_DEVICE_BRAND_WRITE',
    CONFIG_DEVICE_MODEL_READ: 'CONFIG_DEVICE_MODEL_READ',
    CONFIG_DEVICE_MODEL_WRITE: 'CONFIG_DEVICE_MODEL_WRITE',
    CONFIG_PLANS_READ: 'CONFIG_PLANS_READ',
    CONFIG_PLANS_WRITE: 'CONFIG_PLANS_WRITE',
    CONFIG_SIMCARDS_READ: 'CONFIG_SIMCARDS_READ',
    CONFIG_SIMCARDS_WRITE: 'CONFIG_SIMCARDS_WRITE',
    CONFIG_DEVICES_READ: 'CONFIG_DEVICES_READ',
    CONFIG_DEVICES_WRITE: 'CONFIG_DEVICES_WRITE',
    CONFIG_TAGS_READ: 'CONFIG_TAGS_READ',
    CONFIG_TAGS_WRITE: 'CONFIG_TAGS_WRITE',
    DRIVERS_READ: 'DRIVERS_READ',
    DRIVERS_WRITE: 'DRIVERS_WRITE',
    MY_DEVICES_READ: 'MY_DEVICES_READ',
    MY_DEVICES_WRITE: 'MY_DEVICES_WRITE',
    MY_TAGS_READ: 'MY_TAGS_READ',
    MY_SIMCARDS_READ: 'MY_SIMCARDS_READ',
    MY_VEHICLES_READ: 'MY_VEHICLES_READ',
    MY_VEHICLES_WRITE: 'MY_VEHICLES_WRITE',
    MY_TRAILERS_READ: 'MY_TRAILERS_READ',
    MY_TRAILERS_WRITE: 'MY_TRAILERS_WRITE',
    LOCATION_READ: 'LOCATION_READ',
    USERS_READ: 'USERS_READ',
    USERS_WRITE: 'USERS_WRITE',
    ROLES_READ: 'ROLES_READ',
    ROLES_WRITE: 'ROLES_WRITE',
    RULES_READ: 'RULES_READ',
    RULES_WRITE: 'RULES_WRITE',
    API_TOKENS_WRITE: 'API_TOKENS_WRITE',
    API_TOKENS_READ: 'API_TOKENS_READ',
    ALARM_SETTINGS_WRITE: 'ALARM_SETTINGS_WRITE',
    ALARM_SETTINGS_READ: 'ALARM_SETTINGS_READ'
};

export const TYPE_REQUIREMENT = {
    READ: 'READ',
    NOTICE: 'NOTICE'
};

export const ApplicationName = 'Gps Tracking';

export const QueryParameterNames = {
    ReturnUrl: 'returnUrl',
    Message: 'message'
};

export const LogoutActions = {
    LogoutCallback: 'logout-callback',
    Logout: 'logout',
    LoggedOut: 'logged-out'
};

export const LoginActions = {
    Login: 'login',
    LoginCallback: 'login-callback',
    LoginFailed: 'login-failed',
    Profile: 'profile',
    Register: 'register',
    Reset: 'resetPassword'
};

if (!SERVER_BASE_URL) {
    throw new Error('The variable SERVER_BASE_URL is not defined');
}

const prefix = '/authentication';
export const ApplicationPaths = {
    DefaultLoginRedirectPath: '/',
    ApiAuthorizationClientConfigurationUrl: `${SERVER_BASE_URL}/_configuration/web`,
    ApiAuthorizationPrefix: prefix,
    Login: `${prefix}/${LoginActions.Login}`,
    LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
    LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
    Register: `${prefix}/${LoginActions.Register}`,
    Profile: `${prefix}/${LoginActions.Profile}`,
    LogOut: `${prefix}/${LogoutActions.Logout}`,
    LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
    LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
    IdentityRegisterPath: 'Identity/Account/Register',
    IdentityManagePath: 'Identity/Account/Manage',
    IdentityTwoFactorAuthenticationPath: `${SERVER_BASE_URL}/Identity/Account/Manage/TwoFactorAuthentication`,
    Reset: `${prefix}/${LoginActions.Reset}`,
    Home: '/home'
};

export const blobToBase64 = (blobFile: Blob): Promise<string> => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve((reader.result) as string);
        };
        reader.readAsDataURL(blobFile);
    });
};
