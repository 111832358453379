import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
import { AlarmSettingSearchCriteria } from './models/AlarmSettingSearchCriteria';
import { AlarmNotificationTriggerDto, AlarmSettingDto, AlarmTypeDto } from './models/AlarmSettingDto';

class AlarmSettingsService {
    public getList(criteria: AlarmSettingSearchCriteria) {
        return Http.get<Paged<AlarmSettingDto>>('alarmsettings', criteria);
    }

    public getById(id: string) {
        return Http.get<AlarmSettingDto>('alarmsettings/' + id);
    }

    public create(model: AlarmSettingDto) {
        return Http.post<string>('alarmsettings', model);
    }

    public update(model: AlarmSettingDto) {
        return Http.put<string>('alarmsettings/' + model.id, model);
    }

    public remove(model: AlarmSettingDto) {
        return Http.put(`alarmsettings/${model.id}/deactivate`, model);
    }

    public getAlarmTypes() {
        return Http.get<AlarmTypeDto[]>('alarmsettings/alarmtypes');
    }

    public getAlarmTriggersList(id: string) {
        return Http.get<AlarmNotificationTriggerDto[]>('alarmsettings/alarm-triggers/' + id);
    }
}

export default new AlarmSettingsService();
